var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "loginbox" }, [
        _c("div", { staticClass: "login" }, [
          _c("i", {
            staticClass: "el-icon-arrow-left",
            on: {
              click: function($event) {
                return _vm.backbtn()
              }
            }
          }),
          _c(
            "div",
            {
              staticClass: "accountLogin",
              class: { active: _vm.account },
              on: {
                click: function($event) {
                  return _vm.loginMode("account")
                }
              }
            },
            [_vm._v("账号登录")]
          ),
          _c(
            "div",
            {
              staticClass: "verificationLogin",
              class: { active: _vm.verification },
              on: {
                click: function($event) {
                  return _vm.loginMode("verification")
                }
              }
            },
            [_vm._v("验证码登录")]
          )
        ]),
        _vm.account
          ? _c(
              "div",
              [
                _c(
                  "div",
                  [
                    _c(
                      "el-form",
                      {
                        ref: "loginForm",
                        staticClass: "demo-ruleForm",
                        attrs: {
                          autoComplete: "on",
                          model: _vm.loginForm,
                          rules: _vm.loginRules
                        }
                      },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "inputstyle",
                            attrs: { prop: "username" }
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                type: "tel",
                                placeholder: "请输入手机号码",
                                maxlength: "11"
                              },
                              model: {
                                value: _vm.loginForm.username,
                                callback: function($$v) {
                                  _vm.$set(_vm.loginForm, "username", $$v)
                                },
                                expression: "loginForm.username"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "inputstyle",
                            attrs: { prop: "password" }
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                type: "password",
                                placeholder: "请输入密码",
                                maxlength: "20"
                              },
                              model: {
                                value: _vm.loginForm.password,
                                callback: function($$v) {
                                  _vm.$set(_vm.loginForm, "password", $$v)
                                },
                                expression: "loginForm.password"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "span",
                  { staticClass: "registerRule" },
                  [
                    _c("el-checkbox", {
                      on: { change: _vm.cehckChange },
                      model: {
                        value: _vm.checked,
                        callback: function($$v) {
                          _vm.checked = $$v
                        },
                        expression: "checked"
                      }
                    }),
                    _vm._v(" 登录即视为已阅读并同意 "),
                    _c(
                      "b",
                      {
                        on: {
                          click: function($event) {
                            return _vm.showrule("REGISTER")
                          }
                        }
                      },
                      [_vm._v("《" + _vm._s(_vm.GLOBAL) + "用户注册协议》")]
                    ),
                    _c(
                      "b",
                      {
                        on: {
                          click: function($event) {
                            return _vm.showrule("PRIVACY_POLICY")
                          }
                        }
                      },
                      [_vm._v("《" + _vm._s(_vm.GLOBAL) + "用户隐私政策》")]
                    )
                  ],
                  1
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "btn_yellow",
                    style: { background: _vm.isLogin ? "#39394D" : "#39394D" },
                    attrs: { disabled: _vm.islogin },
                    on: {
                      click: function($event) {
                        return _vm.handleLogin("loginForm", "captchaLogin")
                      }
                    }
                  },
                  [_vm._v("登录")]
                )
              ],
              1
            )
          : _vm._e(),
        _vm.verification
          ? _c(
              "div",
              [
                _c(
                  "div",
                  [
                    _c(
                      "el-form",
                      {
                        ref: "captchaLogin",
                        staticClass: "demo-ruleForm",
                        attrs: {
                          autoComplete: "on",
                          model: _vm.captchaLoginForm,
                          rules: _vm.loginRules
                        }
                      },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "inputstyle",
                            attrs: { prop: "username" }
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                type: "tel",
                                placeholder: "请输入手机号码",
                                maxlength: "11"
                              },
                              model: {
                                value: _vm.captchaLoginForm.username,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.captchaLoginForm,
                                    "username",
                                    $$v
                                  )
                                },
                                expression: "captchaLoginForm.username"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "inputstyle rel",
                            attrs: { prop: "captcha" }
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                type: "tel",
                                placeholder: "请输入验证码",
                                maxlength: "6"
                              },
                              model: {
                                value: _vm.captchaLoginForm.captcha,
                                callback: function($$v) {
                                  _vm.$set(_vm.captchaLoginForm, "captcha", $$v)
                                },
                                expression: "captchaLoginForm.captcha"
                              }
                            }),
                            _c(
                              "b",
                              {
                                staticClass: "getCode",
                                on: {
                                  click: function($event) {
                                    return _vm.getCode("captchaLogin")
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.phonetime.time))]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "span",
                  { staticClass: "registerRule" },
                  [
                    _c("el-checkbox", {
                      on: { change: _vm.cehckChange },
                      model: {
                        value: _vm.checked,
                        callback: function($$v) {
                          _vm.checked = $$v
                        },
                        expression: "checked"
                      }
                    }),
                    _vm._v(" 登录即视为已阅读并同意 "),
                    _c(
                      "b",
                      {
                        on: {
                          click: function($event) {
                            return _vm.showrule("REGISTER")
                          }
                        }
                      },
                      [_vm._v("《" + _vm._s(_vm.GLOBAL) + "用户注册协议》")]
                    ),
                    _c(
                      "b",
                      {
                        on: {
                          click: function($event) {
                            return _vm.showrule("PRIVACY_POLICY")
                          }
                        }
                      },
                      [_vm._v("《" + _vm._s(_vm.GLOBAL) + "用户隐私政策》")]
                    )
                  ],
                  1
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "btn_yellow",
                    style: { background: _vm.isLogin ? "#39394D" : "#39394D" },
                    attrs: { disabled: _vm.islogin },
                    on: {
                      click: function($event) {
                        return _vm.handleLogin("loginForm", "captchaLogin")
                      }
                    }
                  },
                  [_vm._v("登录")]
                )
              ],
              1
            )
          : _vm._e(),
        _c("div", { staticClass: "flex-between actionbox" }, [
          _c(
            "div",
            [
              _c("router-link", { attrs: { to: { name: "register" } } }, [
                _vm._v(" 用户注册")
              ])
            ],
            1
          ),
          _c(
            "div",
            [
              _c("router-link", { attrs: { to: { name: "respas" } } }, [
                _vm._v("忘记密码")
              ])
            ],
            1
          )
        ])
      ]),
      _c(
        "van-dialog",
        {
          staticStyle: { "text-align": "center" },
          attrs: {
            type: "alert",
            title: "",
            width: "80%",
            "transform:rotate": "",
            "show-cancel-button": ""
          },
          model: {
            value: _vm.show,
            callback: function($$v) {
              _vm.show = $$v
            },
            expression: "show"
          }
        },
        [
          _c("div", { staticClass: "opopbox", class: { loading: !_vm.msg } }, [
            _vm.msg
              ? _c("div", { domProps: { innerHTML: _vm._s(_vm.msg) } })
              : _c(
                  "div",
                  { staticClass: "loadingView" },
                  [
                    _c("van-loading", {
                      attrs: { size: "2rem", color: "#1989fa" }
                    })
                  ],
                  1
                )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }